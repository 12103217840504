import * as reactHookForm from 'react-hook-form';
import type { Control, FieldError } from 'react-hook-form';
import { UseFormClearErrors } from 'react-hook-form/dist/types/form';

import type { TextFieldProps } from '@material-ui/core/TextField';

import { EditableFieldTypes } from '@cloud-wave/neon-common-lib/app';

import { Input } from 'lib/common/components/Input';
import PhoneInput from 'lib/common/components/PhoneInput';
import Text from 'lib/common/components/Text';

import ReactSelectInput, { TSelectOption } from './components/ReactSelectInput';

const { Controller } = reactHookForm;

export type HookFormInputProps = TextFieldProps & {
  name: string;
  control: Control<any>;
  validationError?: FieldError;
  clearErrors?: UseFormClearErrors<any>;
  busy?: boolean;
  ignoreRows?: boolean;
  type?: keyof typeof EditableFieldTypes;
  ariaRequired?: boolean;
  options?: TSelectOption[];
  onSelectChange?: (value: any) => void;
  multiSelect?: boolean;
  isSearchable?: boolean;
};

const ErrorMessage = ({ message }: { message: string }) => (
  <Text type="small" color="danger">
    {message}
  </Text>
);

const HookFormInput = ({
  name,
  control,
  helperText,
  validationError,
  clearErrors,
  type,
  ignoreRows,
  options,
  onSelectChange,
  multiSelect,
  isSearchable,
  ...otherProps
}: HookFormInputProps) => {
  if (type === EditableFieldTypes.PHONE) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <PhoneInput
            {...field}
            {...otherProps}
            id={otherProps.id || name}
            disabled={otherProps.disabled}
            initialValue={field.value}
            error={validationError && field.value}
            helperText={field.value && validationError?.message}
            onFocus={!otherProps.onFocus && clearErrors ? () => clearErrors(field.name) : otherProps.onFocus}
          />
        )}
      />
    );
  }

  if (type === EditableFieldTypes.SELECT) {
    return (
      <ReactSelectInput
        name={name}
        label={otherProps.label}
        control={control}
        options={options || []}
        onChange={onSelectChange}
        error={validationError as FieldError}
        multiSelect={multiSelect}
        helperText={helperText}
        placeholder={otherProps.placeholder}
        isSearchable={isSearchable}
        disabled={otherProps.disabled}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Input
          {...field}
          {...otherProps}
          id={otherProps.id || name}
          onBlur={(e) => {
            otherProps?.onBlur?.(e);

            field.onBlur();
          }}
          error={validationError && field.value}
          helperText={
            validationError?.message && field.value ? <ErrorMessage message={validationError.message} /> : helperText
          }
          onFocus={!otherProps.onFocus && clearErrors ? () => clearErrors(field.name) : otherProps.onFocus}
          ignoreRows={ignoreRows}
        />
      )}
    />
  );
};

const useHookForm = () => {
  return {
    ...reactHookForm,
    HookFormInput
  };
};

export default useHookForm;
