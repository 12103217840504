import { AgentEmailSignatureInsertOn } from '@cloud-wave/neon-common-lib/common';

export default {
  agentId: '',
  tenantId: '',
  emailSignature: '',
  emailSignatureInsetOn: AgentEmailSignatureInsertOn.NEVER,
  createdAt: '',
  updatedAt: '',
  location: null
};
