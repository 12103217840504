import { faCog } from '@fortawesome/pro-duotone-svg-icons';

import { Permissions } from '@cloud-wave/neon-common-lib';

import MyLocationForm from 'lib/common/contexts/AgentPreferenceContext/MyLocationModal/MyLocationForm';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { usePreferencesContext } from 'lib/common/contexts/PreferencesContext';
import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Checkbox from 'lib/common/components/atoms/Checkbox';
import PreferenceSectionContainer from 'lib/common/components/molecules/Preferences/components/PreferenceSectionContainer';

import PreferencesButton from '../PreferencesButton';

const PreferencesGeneral = () => {
  const {
    state: { enhancedLoggingEnabled },
    actions: { setEnhancedLoggingEnabled }
  } = usePreferencesContext();

  const { hasPermission } = usePermissionsContext();
  const { isSoftphone } = useLayout();

  const {
    actions: { openModal, closeModal }
  } = useSoftphoneModalContext();

  return (
    <PreferenceSectionContainer icon={faCog} title={'General'}>
      {isSoftphone && hasPermission({ type: 'tenant', permission: Permissions.ENHANCED_EMERGENCY_CALLING }) && (
        <PreferencesButton
          title="My Location"
          subtitle="Enhance your outbound emergency calls with precise location information."
          onClick={() =>
            openModal({
              title: 'My Location',
              content: <MyLocationForm editMode closeModal={closeModal} />,
              subtitle:
                'Your administrator has enabled enhanced emergency calling to include location data in outbound emergency calls.'
            })
          }
        />
      )}
      <Checkbox
        checked={enhancedLoggingEnabled}
        label="Enhanced Logging"
        reverse
        onChange={setEnhancedLoggingEnabled}
        subtitle="Stores additional logs and performance metrics in your log file which can be useful when trying to resolve issues."
      />
    </PreferenceSectionContainer>
  );
};

export default PreferencesGeneral;
