import { useEffect } from 'react';

import { Permissions } from '@cloud-wave/neon-common-lib';

import { usePermissionsContext } from '../../PermissionsContext';
import { ISoftphoneModalOptionsProps, useSoftphoneModalContext } from '../../SoftphoneModalContext';
import { useAgentPreferencesContext } from '../Context';
import MyLocationForm from './MyLocationForm';

export default function MyLocationModal({
  setMyLocationModalOpened,
  myLocationModalOpened
}: {
  setMyLocationModalOpened: (value: boolean) => void;
  myLocationModalOpened: boolean;
}) {
  const { hasPermission } = usePermissionsContext();

  const {
    state: { agentPreference }
  } = useAgentPreferencesContext();

  const {
    actions: { openModal, closeModal }
  } = useSoftphoneModalContext();

  const addLocationModalOptions: ISoftphoneModalOptionsProps = {
    title: 'My Location',
    content: <MyLocationForm closeModal={closeModal} />,
    subtitle:
      'Your administrator has enabled enhanced emergency calling to include location data in outbound emergency calls.',
    closeDisabled: true
  };

  useEffect(() => {
    if (
      !myLocationModalOpened &&
      !agentPreference.location &&
      hasPermission({ type: 'tenant', permission: Permissions.ENHANCED_EMERGENCY_CALLING })
    ) {
      openModal(addLocationModalOptions);
      setMyLocationModalOpened(true);
    }
  }, []);

  return null;
}
