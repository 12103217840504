import { ReactNode } from 'react';

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import styles from './preference-section-container.module.scss';

interface IPreferenceSectionProps {
  children: ReactNode;
  icon: IconDefinition;
  title: string;
}

const PreferenceSectionContainer = ({ title, icon, children }: IPreferenceSectionProps) => {
  return (
    <div className={styles['preference-container']}>
      <div className={styles['preference-container__header']}>
        <Icon className="mr-10" size={17} icon={icon} />
        <Text type="heading3">{title}</Text>
        <hr className={styles['preference-container__hr']} />
      </div>
      {children}
    </div>
  );
};

export default PreferenceSectionContainer;
