import { useEffect, useReducer, useState } from 'react';

import _isEqual from 'lodash.isequal';

import Context, {
  BROWSER_PERMISSIONS,
  ESSENTIAL_PERMISSIONS,
  TBrowserPermissions,
  TPermissionState,
  TPermissionStates
} from './Context';

const PERMITTED_PERMISSION_STATES: Record<TBrowserPermissions, TPermissionState[]> = {
  microphone: ['granted'],
  'persistent-storage': ['prompt', 'granted'],
  notifications: ['granted', 'denied', 'prompt']
};

const BROWSER_INTERNAL_PERMISSION_NAMES: Record<string, TBrowserPermissions> = {
  audio_capture: 'microphone',
  durable_storage: 'persistent-storage',
  notifications: 'notifications'
};

const DEFAULT_BROWSER_PERMISSIONS_STATES: TPermissionStates = {
  microphone: 'granted',
  'persistent-storage': 'granted',
  notifications: 'granted'
};

const getPermissions = async () => {
  return Promise.all(
    BROWSER_PERMISSIONS.map((permissionKey) => navigator.permissions.query({ name: permissionKey as PermissionName }))
  );
};

const getPermissionStates = async (permissions: PermissionStatus[]): Promise<TPermissionStates> => {
  return permissions.reduce(
    (allPermissionStates, permission) => ({
      ...allPermissionStates,
      [BROWSER_INTERNAL_PERMISSION_NAMES[permission.name]]: permission.state
    }),
    DEFAULT_BROWSER_PERMISSIONS_STATES
  );
};

const subscribeToPermissionUpdate = (
  permissions: PermissionStatus[],
  updatePermissionStates: React.Dispatch<Partial<TPermissionStates>>
) => {
  permissions.forEach((permission) => {
    permission.onchange = () => {
      updatePermissionStates({ [BROWSER_INTERNAL_PERMISSION_NAMES[permission.name]]: permission.state });
    };
  });
};

const RequirementsProvider = ({ children }) => {
  const [initialPermissionStates, setInitialPermissionStates] = useState(DEFAULT_BROWSER_PERMISSIONS_STATES);

  const [permissionStates, updatePermissionStates] = useReducer(
    (oldState: TPermissionStates, newState: Partial<TPermissionStates>) => ({
      ...oldState,
      ...newState
    }),
    DEFAULT_BROWSER_PERMISSIONS_STATES
  );

  const isPermissionGranted = (permission: TBrowserPermissions, strict = false): boolean => {
    return (
      PERMITTED_PERMISSION_STATES[permission].includes(permissionStates[permission]) &&
      (strict ? permissionStates[permission] === 'granted' : initialPermissionStates[permission] !== 'denied')
    );
  };

  const isAccessRequired = ESSENTIAL_PERMISSIONS.reduce((anyRequired, permission) => {
    return anyRequired || !isPermissionGranted(permission);
  }, false);

  const isReloadRequired = isAccessRequired && !_isEqual(initialPermissionStates, permissionStates);

  useEffect(() => {
    (async () => {
      const permissionStatuses = await getPermissions();

      subscribeToPermissionUpdate(permissionStatuses, updatePermissionStates);

      const initialPermissionStates = await getPermissionStates(permissionStatuses);

      setInitialPermissionStates(initialPermissionStates);
      updatePermissionStates(initialPermissionStates);
    })();
  }, []);

  return (
    <Context.Provider value={{ isPermissionGranted, isAccessRequired, isReloadRequired }}>{children}</Context.Provider>
  );
};

export default RequirementsProvider;
