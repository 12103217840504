import { ReactNode } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';

import ReactSelect from 'lib/common/components/ReactSelect';
import Text from 'lib/common/components/Text';

import ErrorMessage from './ErrorMessage';

export type TSelectOption = { label: string; value: string | boolean };

type TReactSelectInputProps = {
  name: string;
  onChange?: (value: TSelectOption | TSelectOption[] | any) => void;
  options: TSelectOption[];
  helperText?: ReactNode;
  placeholder?: string;
  error?: FieldError;
  control: Control<any>;
  className?: string;
  multiSelect?: boolean;
  isSearchable?: boolean;
  isLoading?: boolean;
  label?: ReactNode;
  disabled?: boolean;
};

function getHelperText(helperText: string | ReactNode, validationError: string | undefined) {
  if (!helperText && !validationError) {
    return null;
  }

  if (validationError) {
    return <ErrorMessage message={validationError} />;
  }

  return (
    <div className="input__helper">
      {typeof helperText === 'string' ? (
        <Text type="helper" color="darkGrey">
          {helperText}
        </Text>
      ) : (
        helperText
      )}
    </div>
  );
}

const ReactSelectInput = ({
  name,
  onChange,
  options,
  placeholder,
  error,
  control,
  className,
  multiSelect,
  helperText,
  isSearchable,
  isLoading,
  label,
  disabled
}: TReactSelectInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: controllerField }) => (
        <div className={className} data-testid={`react-select-${name}`}>
          <ReactSelect
            {...controllerField}
            label={label?.toString()}
            value={
              typeof controllerField.value === 'string'
                ? options.find((option) => option.value === controllerField.value)
                : controllerField.value
            }
            error={Boolean(error && controllerField.value)}
            defaultValue={controllerField.value}
            onChange={onChange}
            placeholder={placeholder}
            options={options}
            isMulti={multiSelect}
            isSearchable={isSearchable}
            isLoading={isLoading}
            isDisabled={disabled}
          />
          {getHelperText(helperText, error && controllerField.value ? error.message : undefined)}
        </div>
      )}
    />
  );
};

export default ReactSelectInput;
