import { useEffect, useState } from 'react';

import { faClose, faWarning } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { useConfigContext } from 'lib/core/config';

import { useRequirementsContext } from 'lib/common/contexts/RequirementsContext';

import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import { HELP_LINKS } from 'lib/common/constants/help';
import { LOCAL_STORAGE_KEYS } from 'lib/common/constants/localStorageKeys';

import appInIframe from 'lib/common/utils/appInIframe';

import styles from './notification-banner.module.scss';

const BANNER_DISPLAY_TIMEOUT_MS = 2000;

export default function NotificationBanner({ className }: { className?: string }) {
  const { config } = useConfigContext();
  const { isPermissionGranted } = useRequirementsContext();

  const [bannerVisible, setBannerVisible] = useState(false);
  const { getStorageItem, setStorageItem } = useLocalStorage();

  const getHideNotificationBannerValue = () => Boolean(getStorageItem(LOCAL_STORAGE_KEYS.HIDE_NOTIFICATION_BANNER));

  const hideBanner = () => {
    setStorageItem(LOCAL_STORAGE_KEYS.HIDE_NOTIFICATION_BANNER, 'true');
    setBannerVisible(false);
  };

  const [userHasHiddenBanner, setUserHasHiddenBanner] = useState(getHideNotificationBannerValue());

  const shouldNeverDisplayBanner = !appInIframe() || userHasHiddenBanner || isPermissionGranted('notifications', true);

  const setHiddenBanner = () => {
    setUserHasHiddenBanner(getHideNotificationBannerValue());
  };

  useEffect(() => {
    window.addEventListener('storage', setHiddenBanner, false);

    if (shouldNeverDisplayBanner) {
      return;
    }

    const timeout = setTimeout(() => {
      setBannerVisible(true);
    }, BANNER_DISPLAY_TIMEOUT_MS);

    return () => {
      clearTimeout(timeout);

      window.removeEventListener('storage', setHiddenBanner);
    };
  }, [shouldNeverDisplayBanner]);

  if (shouldNeverDisplayBanner || !bannerVisible) {
    return null;
  }

  return (
    <div className={cx(styles['notification-banner'], className)} data-testid="notification-banner">
      <Icon icon={faWarning} size={17} />
      <Text type="extraSmall" className={styles['notification-banner__text']}>
        Notifications aren’t currently enabled. You’ll need to{' '}
        <Text type="link" href={window.location.origin}>
          open {config.BRAND.productName} directly
        </Text>{' '}
        and{' '}
        <Text bold inline>
          enable notifications.
        </Text>{' '}
        <Text type="link" href={`${config.BRAND.helpDocsUrl}${HELP_LINKS.ENABLE_NOTIFICATIONS}`}>
          Why?
        </Text>
        .
      </Text>
      <ClickableIcon aria-label="Hide banner" className="mb-auto" size={15} icon={faClose} onClick={hideBanner} />
    </div>
  );
}
