import { FC } from 'react';
import Select, {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  LoadingIndicatorProps,
  Props,
  components
} from 'react-select';

import { faChevronDown, faCircleNotch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import ClickableIcon from '../ClickableIcon';
import Icon from '../Icon';
import Label from '../Label';
import { getDefaultStyles } from './reactSelectStyles';

import VARS from 'css/export-vars.module.scss';

import styles from './react-select.module.scss';

const DropdownIndicator: FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon={faChevronDown} size={13} color="darkGrey" className="ml-5 mr-5" />
    </components.DropdownIndicator>
  );
};

const ClearIndicator: FC<ClearIndicatorProps> = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <ClickableIcon icon={faTimes} size={15} />
    </components.ClearIndicator>
  );
};

const LoadingIndicator: FC<LoadingIndicatorProps> = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon icon={faCircleNotch} spin size={13} color="darkGrey" />
    </components.ClearIndicator>
  );
};

export default function ReactSelect(
  props: {
    small?: boolean;
    label?: string;
    error?: boolean;
  } & Props
) {
  const { isSoftphone } = useLayout();

  const selectElement = (
    <Select
      isSearchable
      styles={getDefaultStyles({ error: props.error, isSoftphone: props.small || isSoftphone, small: props.small })}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary50: VARS.secondary,
          primary75: VARS.secondary,
          primary: VARS.secondary,
          neutral5: VARS.lightGrey
        }
      })}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      aria-label={props.label || props.placeholder?.toString()}
      {...props}
      components={{ DropdownIndicator, ClearIndicator, LoadingIndicator, ...props.components }}
    />
  );

  if (props.label) {
    return (
      <Label
        id={props.label}
        text={props.label}
        className={cx(styles['react-select__label'], { [styles['react-select__label--error']]: props.error })}
      >
        {selectElement}
      </Label>
    );
  }

  return selectElement;
}
