export default async function requestNotificationPermission() {
  try {
    if (typeof Notification === void 0 || !Notification) {
      return Promise.resolve();
    }

    if (Notification.permission !== 'granted') {
      await Notification.requestPermission();
    }

    return Notification.permission;
  } catch {
    return Promise.resolve();
  }
}
