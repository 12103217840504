import { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';

import {
  TAgentLocationStreetDirections,
  agentLocationStreetDirections,
  agentPreferenceLocationSchema
} from '@cloud-wave/neon-common-lib';

import useHookForm from 'lib/common/hooks/useHookForm/useHookForm';

import Button from 'lib/common/components/Button';
import Text from 'lib/common/components/Text';

import US_STATES from 'lib/common/constants/us-states.json';

import { useAgentPreferencesContext } from '../Context';

import styles from './my-location-form.module.scss';

export default function MyLocationForm({ closeModal, editMode }: { closeModal: () => void; editMode?: boolean }) {
  const [skip, setSkip] = useState(false);
  const [onClear, setOnClear] = useState(false);

  const {
    state: { agentPreference },
    actions: { updatePreference }
  } = useAgentPreferencesContext();

  const { useForm, HookFormInput } = useHookForm();

  const {
    control,
    setValue,
    formState: { errors, isValid },
    clearErrors,
    getValues
  } = useForm({
    defaultValues: agentPreference.location || {
      streetInfo: '',
      city: '',
      state: '',
      postalCode: '',
      streetNumber: '',
      unitNumber: '',
      streetDirection: '-'
    },
    mode: 'onBlur',
    resolver: yupResolver(agentPreferenceLocationSchema)
  });

  if (onClear) {
    return (
      <div className={styles['my-location-form__container']}>
        <Text>
          Not adding your location may impact the accuracy of emergency calls. Providing it helps responders reach you
          faster in an emergency.
          <br />
          <br /> Would you still like to clear your location?
        </Text>
        <div className={cx(styles['my-location-form__gap-20'], styles['my-location-form__flex-end'])}>
          <Button onClick={() => setOnClear(false)} styleType="SECONDARY">
            Add My Location
          </Button>
          <Button
            styleType="DANGER"
            asyncAction
            onClick={async () => {
              await updatePreference({ location: null });
              closeModal();
            }}
          >
            Clear Anyway
          </Button>
        </div>
      </div>
    );
  }

  if (skip) {
    return (
      <div className={styles['my-location-form__container']}>
        <Text>
          Not adding your location may impact the accuracy of emergency calls. Providing it helps responders reach you
          faster in an emergency.
          <br />
          <br /> Would you like to skip without adding it?
        </Text>
        <div className={cx(styles['my-location-form__gap-20'], styles['my-location-form__flex-end'])}>
          <Button onClick={() => setSkip(false)} styleType="SECONDARY">
            Add My Location
          </Button>
          <Button styleType="DANGER" onClick={() => closeModal()}>
            Skip Anyway
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles['my-location-form__container']}>
      <div className={styles['my-location-form__content']}>
        <Text className="mb-10">
          {editMode
            ? 'Enter your current location below.'
            : 'Enter your location below. You can update it anytime in your preferences.'}
        </Text>
        <HookFormInput
          autoFocus
          label="Unit / Apt / Suite  •  Optional"
          InputLabelProps={{ shrink: true, required: false }}
          name="unitNumber"
          onBlur={(e) => setValue('unitNumber', e.target.value.trim())}
          validationError={errors['unitNumber']}
          clearErrors={clearErrors}
          fullWidth
          control={control}
          placeholder="123"
        />
        <HookFormInput
          label="House Number"
          onBlur={(e) => setValue('streetNumber', e.target.value.trim())}
          InputLabelProps={{ shrink: true, required: false }}
          name="streetNumber"
          validationError={errors['streetNumber']}
          clearErrors={clearErrors}
          fullWidth
          control={control}
          ariaRequired
          required
          placeholder="123"
        />
        <HookFormInput
          label="Street Direction  •  Optional"
          InputLabelProps={{ shrink: true, required: false }}
          name="streetDirection"
          validationError={errors['streetDirection']}
          clearErrors={clearErrors}
          fullWidth
          control={control}
          onSelectChange={({ value }: { value: TAgentLocationStreetDirections }) => setValue('streetDirection', value)}
          type="SELECT"
          options={agentLocationStreetDirections.map((direction) => ({ label: direction, value: direction }))}
        />
        <HookFormInput
          label="Street Name (include suffix, e.g. Main St)"
          onBlur={(e) => setValue('streetInfo', e.target.value.trim())}
          InputLabelProps={{ shrink: true, required: false }}
          name="streetInfo"
          validationError={errors['streetInfo']}
          clearErrors={clearErrors}
          fullWidth
          control={control}
          ariaRequired
          required
          placeholder="Main Street"
        />
        <HookFormInput
          label="City"
          InputLabelProps={{ shrink: true, required: false }}
          name="city"
          validationError={errors['city']}
          onBlur={(e) => setValue('city', e.target.value.trim())}
          clearErrors={clearErrors}
          fullWidth
          control={control}
          ariaRequired
          required
          placeholder="Springfield"
        />
        <HookFormInput
          label="State / Province"
          InputLabelProps={{ shrink: true, required: false }}
          name="state"
          validationError={errors['state']}
          clearErrors={clearErrors}
          fullWidth
          control={control}
          ariaRequired
          onSelectChange={({ value }: { value: string }) => setValue('state', value)}
          required
          placeholder="California"
          type="SELECT"
          options={US_STATES}
        />
        <HookFormInput
          label="Zip / Postal Code"
          InputLabelProps={{ shrink: true, required: false }}
          name="postalCode"
          validationError={errors['postalCode']}
          onBlur={(e) => setValue('postalCode', e.target.value.trim())}
          clearErrors={clearErrors}
          fullWidth
          required
          control={control}
          ariaRequired
          placeholder="90210"
        />
      </div>
      <section aria-label="footer" className={styles['my-location-form__footer']}>
        <div>
          {agentPreference.location && (
            <Button onClick={() => setOnClear(true)} styleType="SECONDARY">
              Clear
            </Button>
          )}
        </div>
        <div className={cx(styles['my-location-form__flex-end'], styles['my-location-form__gap-10'])}>
          {!agentPreference.location && (
            <Button onClick={() => setSkip(true)} styleType="SECONDARY_DANGER">
              Skip
            </Button>
          )}
          <Button
            disabled={!isValid}
            asyncAction
            onSuccess={() => closeModal()}
            onClick={async () => {
              await updatePreference({ location: getValues() });
              closeModal();
            }}
          >
            Save
          </Button>
        </div>
      </section>
    </div>
  );
}
