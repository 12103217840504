import { faEnvelope } from '@fortawesome/pro-duotone-svg-icons';

import { Permissions } from '@cloud-wave/neon-common-lib';

import { useAgentPreferencesContext } from 'lib/common/contexts/AgentPreferenceContext';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';

import PreferenceSectionContainer from 'lib/common/components/molecules/Preferences/components/PreferenceSectionContainer';

import PreferencesButton from '../PreferencesButton';

interface IPreferencesAudioDevices {}

const PreferencesEmail = ({}: IPreferencesAudioDevices) => {
  const { hasPermission } = usePermissionsContext();

  const {
    actions: { setPreferenceModalOpen }
  } = useAgentPreferencesContext();

  const hasEmailPermission = hasPermission({ type: 'tenant', permission: Permissions.EMAIL });

  if (!hasEmailPermission) {
    return null;
  }

  return (
    <PreferenceSectionContainer icon={faEnvelope} title={'Email'}>
      <PreferencesButton title="Manage Signature" onClick={() => setPreferenceModalOpen(true)} />
    </PreferenceSectionContainer>
  );
};

export default PreferencesEmail;
