import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import styles from './preferences-button.module.scss';

export default function PreferencesButton({
  subtitle,
  title,
  onClick
}: {
  subtitle?: string;
  title: string;
  onClick: () => void;
}) {
  return (
    <button className={cx(styles['preferences-button'], 'no-styles-button')} onClick={onClick}>
      <div>
        <Text>{title}</Text>
        <Text type="label">{subtitle}</Text>
      </div>
      <Icon size={17} className={styles['preferences-button__icon']} icon={faChevronRight} />
    </button>
  );
}
