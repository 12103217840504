import { useEffect, useState } from 'react';

import {
  faBrowsers,
  faCirclePhone,
  faEnvelope,
  faHeadset,
  faHourglass,
  faMessageSmile,
  faShieldCheck
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faRightFromBracket,
  faBrowsers as farBrowsers,
  faHourglass as farHourglass,
  faShieldCheck as farShieldCheck
} from '@fortawesome/pro-regular-svg-icons';
import { faCloud, faKey } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { useConfigContext } from 'lib/core/config';
import { useStreamsContext } from 'lib/core/context/StreamsProvider';

import useIsSmallSoftphone from 'lib/common/hooks/useIsSmallSoftphone';
import useIsSoftphoneQuery from 'lib/common/hooks/useIsSoftphoneQuery';

import Button from 'lib/common/components/Button';
import IconConstellation from 'lib/common/components/IconConstellation';
import Text from 'lib/common/components/Text';

import { HELP_LINKS } from 'lib/common/constants/help';

import { CURRENT_USER_FULLNAME_KEY } from '../../../constants';
import { useForgetDevice } from '../../../hooks/useForgetDevice';
import { InfoCard, InfoCardGroup } from '../../InfoCard';

import styles from './hello.module.scss';

const LOGIN_HELP_TIMEOUT_S = 20 * 1000;

const Main = ({ sso }: { sso: boolean }) => {
  const { initializeCCP } = useStreamsContext();
  const {
    config: {
      BRAND: { helpDocsUrl }
    }
  } = useConfigContext();
  const isSoftphone = useIsSoftphoneQuery();
  const isSmallSoftphone = useIsSmallSoftphone();
  const { forgetDevice } = useForgetDevice();

  const [loading, setLoading] = useState(false);
  const [extendedLoad, setExtendedLoad] = useState(false);
  const [currentUser, setCurrentUser] = useState<null | string>(null);

  const signIn = async () => {
    setLoading(true);
    setTimeout(() => {
      setExtendedLoad(true);
    }, LOGIN_HELP_TIMEOUT_S);
    await initializeCCP();
  };

  useEffect(() => {
    setCurrentUser(sessionStorage.getItem(CURRENT_USER_FULLNAME_KEY) || null);

    // If we're in development mode, click on Sign In button to automatically sign in
    if (process.env.NODE_ENV === 'development') {
      signIn();
    }
  }, []);

  return (
    <div className={styles['hello']}>
      <div className={isSoftphone ? 'mb-20' : 'mb-40'}>
        <Text className="mb-5" type={'heading1'}>
          {currentUser ? `Welcome back, ${currentUser}!` : 'Hello!'}
        </Text>
        <Text color="darkGrey">
          We'll start off by getting you to sign in to {sso ? 'your provider' : 'Amazon Connect'}.{' '}
          <Text href={`${helpDocsUrl}${HELP_LINKS.CONNECT.SIGN_IN}`}>Why?</Text>
        </Text>
      </div>

      <InfoCardGroup
        className="mb-20"
        groupSize={isSoftphone ? 'SMALL' : 'LARGE'}
        small={isSoftphone}
        minimal={isSmallSoftphone}
        cards={[
          {
            title: 'Pop-ups',
            icon: faBrowsers,
            smallIcon: farBrowsers,
            text: (
              <Text>
                The sign in page will open in a new window or tab, so you'll need to{' '}
                <Text href={`${helpDocsUrl}${HELP_LINKS.CONNECT.WINDOW_NOT_APPEARING}`}>allow popups</Text>.
              </Text>
            )
          },
          {
            title: 'Next Steps',
            icon: faShieldCheck,
            smallIcon: farShieldCheck,
            text: `After signing into ${
              sso ? 'your provider' : 'Amazon Connect'
            }, the window will close, and we'll continue automatically.`
          }
        ]}
      />

      <div
        className={cx(isSoftphone ? 'mb-20' : 'mb-40', styles['hello__extended-load'], {
          [styles['hello__extended-load--expanded']]: extendedLoad
        })}
        aria-hidden={!extendedLoad}
      >
        {isSmallSoftphone && <hr />}
        <InfoCard
          type={isSmallSoftphone ? 'PRIMARY' : 'SECONDARY'}
          small={isSoftphone}
          minimal={isSmallSoftphone}
          smallIcon={farHourglass}
          size={isSoftphone ? 'SMALL' : 'LARGE'}
          title="Still Going"
          icon={faHourglass}
          text={
            <Text>
              We're working hard to sign you in! If you've been waiting for some time you can try{' '}
              {extendedLoad && (
                <Text className={styles['hello__black-anchor']} onClick={() => window.location.reload()}>
                  reloading the page
                </Text>
              )}
              .
            </Text>
          }
        />
      </div>

      <div className={styles['hello__buttons']}>
        <Button onClick={signIn} busy={loading} icon={sso ? faKey : faCloud}>
          Sign in with {sso ? 'your provider' : 'Amazon Connect'}{' '}
        </Button>
        {currentUser && (
          <Button
            onClick={async () => {
              await forgetDevice();
              window.location.reload();
            }}
            asyncAction
            icon={faRightFromBracket}
            styleType="SECONDARY"
          >
            Not You?
          </Button>
        )}
      </div>
    </div>
  );
};

const Info = () => (
  <IconConstellation leftIcon={faCirclePhone} topIcon={faEnvelope} rightIcon={faMessageSmile} centerIcon={faHeadset} />
);

export const Hello = { Main, Info };
